import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "@components/tw/checkOutForm/CheckoutForm";
import { useLocation, useNavigate } from "react-router";
import { clear } from "console";
import { useAuth } from "@auth/AuthProvider";
import { useClientSecret } from "@contexts/clientSecretProvider";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

stripePromise.then((data) => console.log("prmise data", data));

const CheckOut = () => {
  // const [error, setError] = useState<string | null>(null);

  const location = useLocation();
  const { clientSecret, setClientSecret } = useClientSecret();
  if (clientSecret) {
    const { coins, amount, timeZone } = location.state;
  }

  const navigate = useNavigate();
  const auth = useAuth();

  const appearance = {
    theme: "stripe" as const,
  };
  const options = {
    clientSecret,
    appearance,
  };
  useEffect(() => {
    if (!clientSecret) navigate("/dashboard");
  }, []);

  return (
    <>
      {clientSecret !== "" && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

export default CheckOut;

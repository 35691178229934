import CoinPackageHeader from "@components/tw/common/coinPackageHeader/CoinPackageHeader";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/home") {
      navigate("/dashboard", { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <main className="flex-1 border-bgray border-r-2 relative">
      <CoinPackageHeader />
      <div>
        <Outlet />
      </div>
    </main>
  );
};

export default HomePage;
